.searchable-select {
  position: relative;
  width: 100%;
  padding: 0px;
  border: 0px;
  &.disabled {
    pointer-events: none;
    background-color: #e9ecef;
  }
  input {
    margin: 0px;
    width: 100%;
    height: 100%;
    border: 1px solid #cecece;
    padding: 4px 20px 4px 8px;
  }
  ul {
    z-index: 999999;
    position: absolute;
    left: 0px;
    right: 0px;
    background-color: #fff;
    list-style: none;
    padding: 0px;
    border: 1px solid #cecece;
    max-height: 30vh;
    overflow-y: auto;
    margin-bottom: 0px;
    @media (hover: none) {
      -ms-overflow-style: none; /* IE, Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar { /*Chrome, Safari and Opera */
        display: none;
      }
    }
    li {
      padding: 8px 10px;
      background-color: #fff;
      cursor: pointer;
      border-bottom: 1px solid #efefef;
      &.active {
        background-color: #3297FD;
        color: #fff;
      }
      &.selected {
        background-color: #64b1ff;
      }
      &.disabled {
        cursor: default;
        opacity: 0.8;
        background-color: #fff;
      }
    }
  }
  .arrow-down {
    position: absolute;
    right: 12px;
    top: 50%;
    width: 0;
    height: 0;
    display: inline-block;
    vertical-align: middle;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #999 transparent transparent transparent;
  }
}