.ba-start {
  min-height: 100%;
  .start-form {
    background: #ffffff;
    .title {
      
    }
    .subtitle {
      font-size: 13px;
    }
    .search-input {
      font-size: 14px;
      padding: 8px 10px;
    }
    .email-input {
      font-size: 14px;
      padding: 10px 16px;
    }
    .start-form-bt {
      width: 128px;
    }
  }
  .oglasi {
    display: none;
    padding: 20px 0px 0px 0px;
    height: 180px;
    overflow-y: hidden;
  }
  .navbar {
    .navbar-menu-wrapper:nth-child(1) {
      background-color: $primary;
      .navbar-nav {
        &.navbar-nav-right {
          margin-left: auto;
          a {
            color: $white;
            font-size: 14px;
            margin-right: 24px;
            .btn-inverse-light {
              color: $primary-dark;
              &:hover {
                background-color: $white;
              }
            }
          }
          @media (max-width: 720px) {
            // display: none !important;
            white-space: nowrap;
          }
        }
        .phone {
          color: $white;
          font-size: 13px;
          margin-left: 10px;
          margin-right: 20px;
        }
        a {
          button {
            color: $white;
            padding: 12px 14px;
            &:hover {
              background: transparent;
              color: #F5F5F5;
            }
          }
        }
      }
    }
    .navbar-menu-wrapper:nth-child(2) {
      background: $white;
      .navbar-nav {
        &.navbar-nav-right {
          margin-left: auto;
          a {
            color: #757575;
            font-size: 14px;
            margin-right: 24px;
          }
          @media (max-width: 720px) {
            // display: none !important;
            white-space: nowrap;
          }
        }
        .nav-item {
          .logo {
            width: 160px;
            filter: none;
          }
        }
      }
      .navbar-brand {
        img {
          filter: none;
        }
      }
    }
  }
  .bg-image.vertical-center {
    min-height: 100vh;
    .auth-form-light {
      .error {
        color: #fb7575;
        margin-top: 20px;
        margin-bottom: 0px;
        font-size: 13px;
      }
      .form-group {
        margin-top: 2.5rem;
        font-size: 12px;
        .btn {
          margin-top: 10px;
          width: auto;
          min-width: auto;
          cursor: pointer;
          padding: 10px 30px;
          border-radius: 4px;
          border-color: $white;
          font-size: 14px;
          &.blue-bg {
            background-color: $primary;
            &:hover {
              background-color: $primary-dark;
            }
          }
        }
        .message-below-field {
          position: absolute;
          font-size: 10px;
          bottom: -18px;
          color: $text-muted;
          left: 12px;
        }
      }
    }
  }
}