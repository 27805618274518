.row {
  .wy-db-pagination {
    .pagination {
      margin-bottom: 0px;
      margin-left: 10px;
      .page-item {
        &.active {
          .page-link {
            background-color: $primary;
            border-color: $primary;
            color: $white;
          }
        }
        .page-link {
          color: $primary;
          padding: 0.14rem 0.45rem;
          border-color: #dee2e6;
          box-shadow: none !important;
        }
      }
    }
  }
  .wy-db-controls {
    display: flex;
    justify-content: flex-end;
    .dropdown, .btn {
      margin-right: 10px;
      .dropdown-item {
        color: initial;
      }
    }
  }
  .wy-db-list-settings {
    justify-content: flex-end;
    i {
      cursor: pointer;
      font-size: 14px;
      opacity: 0.3;
      transition: opacity .3s;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.card {
  .card-body {
    padding: 1rem 1rem;
    
    .table-responsive {
      position: relative;
      table {
        th {
          vertical-align: middle;
        }
        tr {
          .video-preview {
            position: relative;
            .preview-static {
              border: 0.5px solid #c5c5c5;
              
            }
            .preview-dynamic {
              border: 0.5px solid #c5c5c5;
              position: absolute;
              left: 0px;
              opacity: 0;
              transition: opacity .3s;
            }
          }
          &:hover {
            .video-preview {
              .preview-dynamic {
                opacity: 1;
              }
            }
          }
        }
        td, th {
          padding: 8px 12px;
          
          .form-group {
            margin-bottom: 0px;
            .form-check {
              margin: 0px;
              .form-check-label {
                margin: 0px;
                .input-helper {
                  &:before {
                    border-width: 1.4px;
                  }
                }
              }
            }
          }
          
          img {
            border-radius: 0%;
            width: auto;
            max-width: 120px;
            background: black;
            max-height: 70px;
            height: auto;
            cursor: pointer;
          }
        }
      } 
    }
    .form-group {
      position: relative;
      .message-below-field {
        position: absolute;
        font-size: 10px;
        bottom: -18px;
        color: #a7a7a7;
        left: 12px;
      }
      textarea {
        padding: 12px;
      }
      td, th {
        padding: 2px 10px;
        
        .form-group {
          margin-bottom: 0px;
          .form-check {
            margin: 0px;
            .form-check-label {
              margin: 0px;
              .input-helper {
                &:before {
                  border-width: 1.4px;
                }
              }
            }
          }
        }
        td, th {
          padding: 8px 12px;
          
          .form-group {
            margin-bottom: 0px;
            .form-check {
              margin: 0px;
              .form-check-label {
                margin: 0px;
                .input-helper {
                  &:before {
                    border-width: 1.4px;
                  }
                }
              }
            }
          }
          
          img {
            border-radius: 0%;
            width: auto;
            max-width: 120px;
            background: black;
            max-height: 70px;
            height: auto;
            cursor: pointer;
          }
        }
      }
    }
    
    .image-preview {
      max-height: 480px;
      max-width: 100%;
    }
    
  }
}

