/* Navbar */

.navbar {
  /*left: $sidebar-width-lg;*/
  left: 0;
  transition: left $action-transition-duration $action-transition-timing-function,
    width $action-transition-duration $action-transition-timing-function;
  -webkit-transition: left $action-transition-duration $action-transition-timing-function,
    width $action-transition-duration $action-transition-timing-function;
  -moz-transition: left $action-transition-duration $action-transition-timing-function,
    width $action-transition-duration $action-transition-timing-function;
  -ms-transition: left $action-transition-duration $action-transition-timing-function,
    width $action-transition-duration $action-transition-timing-function;

  .navbar-menu-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    color: $navbar-menu-color;
    padding-left: 15px;
    /*padding-right: 15px;*/
   /* width: calc(100% - #{$sidebar-width-lg});*/
    width: 100%;
    height: $navbar-height;
    background: $primary;
    &.front-page-wrapper {
      height: $navbar-front-height;
    }
    @media (max-width: 991px) {
      width: 100%;
    }
    .navbar-toggler {
      border: 0;
      color: $navbar-menu-color;
      margin-right: 0;
      &:not(.navbar-toggler-right) {
        @media (max-width: 991px) {
          display: none;
        }
      }
    }

    .navbar-text {
      font-size: $font-size-base;
    }

    .navbar-nav {
      height: 100%;
      @include flex-direction(row);
      @include align-items(stretch);
      .nav-item {
        @include display-flex();
        @include align-items(center);
        padding-left: 1rem;
        padding-right: 1rem;
        line-height: 1;
        @media (max-width: 767px) {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
        .nav-link {
          color: $navbar-menu-color;
          font-size: $navbar-font-size;
          i {
            font-size: $navbar-icon-font-size;
            color: $navbar-menu-color;
          }
          .nav-profile-img {
            height: 34px;
            width: 34px;
            border-radius: 50%;
          }
          .profile-text {
            margin-left: 15px;
            @media (max-width: 375px) {
              display: none;
            }
          }
        }
        .dropdown {
          .dropdown-toggle {
            padding: 0;
            font-weight: normal;
            &:after {
              content: "\F140";
              display: inline-block;
              font: normal normal normal 24px/1 "Material Design Icons";
              font-size: inherit;
              text-rendering: auto;
              line-height: inherit;
              -webkit-font-smoothing: antialiased;
              border: 0;
              vertical-align: baseline;
            }
            i {
              font-size: 1rem;
              line-height: 1.75;
            }
            &.bg-transparent {
              background: transparent;
              border: none;
            }
          }
          .badge {
            margin-left: 2.5rem;
          }
          .count-indicator {
            position: relative;
            border-radius: 100%;
            text-align: center;
            margin-right: 0;
            i {
              margin-right: 0;
              vertical-align: middle;
            }
            .count {
              position: absolute;
              left: 50%;
              width: 14px;
              height: 14px;
              border-radius: 100%;
              top: 2px;
              font-size: 10px;
              color: $white;
              background: theme-color(danger);
              padding-top: 2px;
            }
            &:after {
              display: none;
            }
            .count-varient1 {
              background: theme-color(warning);
            }
            .count-varient2 {
              background: theme-color(success);
            }
          }
          i {
            margin-right: 0.5rem;
            vertical-align: middle;
            .rtl & {
              margin-left: 0.5rem;
              margin-right: 0;
            }
          }
          .navbar-dropdown {
            background-color: $navbar-dropdown-bg;
            font-size: 0.9rem;
            margin-top: 1rem;
            position: absolute;
            top: $navbar-height;
            cursor: pointer;
            left: auto;
            right: auto;
            //transform: translate3d(0px, 58px, 0px) !important;
            transform: translate3d(0px, $navbar-height, 0px) !important;
            border: 0px !important;
            .rtl & {
              left: auto;
              right: 0;
            }
            .navbar-nav-right & {
              right: 0;
              left: auto;
              .rtl & {
                right: auto;
                left: 0;
              }
            }
            &.dropdown-left {
              left: 0;
              right: auto;
            }
            min-width: 100%;
            -webkit-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.1);
            @include border-radius(4px);
            @media (max-width: 991px) {
              right: -85px;
            }
            @extend .dropdownAnimation;
            border: 1px solid rgba(182, 182, 182, 0.1);
            padding: 0;
            @include border-radius(0);
            overflow: hidden;
            .dropdown-item {
              @extend .d-flex;
              @extend .align-items-center;
              margin-bottom: 0;
              position: relative;
              border-bottom: 1px solid #ffffff5c;
              padding: 8px 8px 18px 8px;
              line-height: 1.3;
              font-size: $font-size-base;
              margin-top: 0px !important;
              color: $white;
              .preview-subject {
                width: calc(100% - 36px);
                white-space: break-spaces;
              }
              .date-created {
                position: absolute;
                bottom: 2px;
                right: 8px;
                font-size: 10px;
                font-weight: 300;
                color: #ffffffc9;
              }
              &.text-small {
                font-size: 12px;
              }
              i {
                font-size: 24px;
              }

              .ellipsis {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .preview-icon {
                width: 40px;
                height: 40px;
              }
              .small-text {
                font-size: 0.75rem;
              }
              .badge {
                margin-bottom: 0.5rem;
              }
            }

            .dropdown-divider {
              margin: 0;
            }
            &.navbar-dropdown-large {
              width: 300px;
              @media (max-width: 767px) {
                width: 100%;
              }
              .dropdown-item {
                white-space: normal;
                color: $dark !important;
                &:nth-child(2n) {
                  background: $primary-dark;
                  color: $white;
                }
              }
            }
          }
          .close-icon {
            width: 20px;
            height: 20px;
            padding: 3px 5px;
            margin-right: 0px;
            vertical-align: middle;
            opacity: 0.4;
            position: relative;
            top: -6px;
            right: -6px;
            &:hover {
              opacity: 0.8;
            }
            margin: 0px;
          }
          @media (max-width: 767px) {
            position: static;
            .navbar-dropdown,
            .navbar-dropdown-large {
              width: 100%;
              margin: auto;
              left: 0px;
              right: 0px;
              width: 100%;
              top: 69px;
            }
          }
          @media (max-width: 991px) {
            .navbar-dropdown {
              min-width: calc(100% + 48px);
            } 
          }
        }
        &.nav-search {
          .input-group {
            width: 260px;
            .input-group-append {
              background: transparent;
            }
            .form-control {
              background: $navbar-item-bg;
              border: 0;
              color: $white;
              padding: 0.5rem 1rem;
              border-top-left-radius: 50px;
              border-bottom-left-radius: 50px;
              height: auto;
              .rtl & {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
              }
            }
            .input-group-text {
              background: $navbar-item-bg;
              border: 0;
              color: $white;
              padding: 0.5rem 1rem;
              border-top-right-radius: 50px;
              border-bottom-right-radius: 50px;
              .rtl & {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
              }
            }
          }
        }
        &.nav-language {
          .navbar-dropdown {
            transform: translate3d(-13px, 28px, 0px) !important;
            position: relative;
            min-width: 48px;
            margin: 0px;
            background-color: $navbar-dropdown-bg;
            color: #fff;
            padding: 0px;
            text-align: center;
            .dropdown-item {
              padding: 8px 8px 8px 8px;
            }
            &:hover {
              
            }
          }
        }
        &.nav-profile {
          background-color: $navbar-item-bg;
          .nav-link {
            // padding-right: 18px;
            height: 40px;
            @media (max-width: 425px) {
              height: 32px;
              padding-right: 22px;
            }
            .profile-name {
              @media (max-width: 767px) {
                display: none;
              }
            }
          }
        }
        &.nav-user {
          /*padding-right: 1rem;*/
           padding-right: 0rem;
          @media (max-width: 360px) {
            padding-right: 0rem;
          }
        }
        @media (max-width: 576px) {
          border: none;
          padding-right: 0rem;
        }
       
      }
      &.navbar-nav-right {
        position: relative;
        height: $navbar-height;
        @media (min-width: 992px) {
          /*margin-left: auto;*/
          .nav-item {
            .dropdown {
              position: static;
              .dropdown-toggle {
                box-shadow: none;
                &:after {
                  content: "\F140";
                  display: inline-block;
                  font: normal normal normal 24px/1 "Material Design Icons";
                  font-size: inherit;
                  text-rendering: auto;
                  line-height: inherit;
                  -webkit-font-smoothing: antialiased;
                  border: 0;
                  vertical-align: baseline;
                }
                &.toggle-arrow-hide {
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
      &.search {
        align-items: center;
        margin-right: 12px;
        margin-left: auto;
        cursor: pointer;
        opacity: 0;
        .search-form {
          input {
            padding: 5px 10px;
          }
        }
      }
      &.header-links {
        height: $navbar-height;
        padding-left: 0%;
        .nav-item {
          margin: 0;
          padding: 0;
          .logo {
            width: 100px;
            filter: brightness(0) invert(1);
          }
          .nav-link {
            height: $navbar-height;
            font-size: $navbar-font-size;
            padding: 16px 25px;
            display: flex;
            align-items: center;

            i {
              margin-right: 10px;
              font-size: 21px;

              .rtl & {
                margin-right: 0;
                margin-left: 10px;
              }
            }
          }

          &.active {
            background: rgba($white, 0.13);
          }
        }
      }
    }
  }
  .logo {
    max-width: 180px;
    height: 100%;
    // max-height: 24px;
    filter: none;
    display: flex;
    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
  }
  //navbar color variations
  &.navbar-dark {
    .count-varient1 {
      background: theme-color(warning);
    }
    .count-varient2 {
      background: theme-color(success);
    }
  }

  &.navbar-light {
    .navbar-brand {
      color: $black;
    }
    .navbar-menu-wrapper {
      background: $white;
      .navbar-nav {
        .nav-item {
          border-color: $light-color-icon-bg;
          .nav-link {
            color: $black;
          }
          &.nav-search {
            .input-group {
              .form-control,
              .input-group-text {
                background: $light-color-icon-bg;
              }
              .form-control {
                @include input-placeholder {
                  color: $black;
                }
              }
              .input-group-text {
                color: $black;
              }
            }
          }
        }
      }
      .navbar-toggler {
        i:before {
          background: $light-color-icon-bg;
          color: $black;
        }
      }
    }
    @extend .navbar-dark;
  }
  @media (max-width: 991px) {
    left: 0;
    height: $navbar-height;
    padding: 0;
  }
  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (max-width:991px) {
  .navbar {
    flex-direction: row;
    .navbar-menu-wrapper {
      .navbar-brand {
        min-width: 64px;
        padding: 0px;
        &.brand-logo {
          display: inline-block;
        }
        &.brand-logo-mini {
          display: none;
        }
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width:767px) {
  .navbar {
    .navbar-menu-wrapper {
      .navbar-brand {
        min-width: 48px;
        margin-right: 4px;
        padding: 0px;
        &.brand-logo {
          display: none;
        }
        &.brand-logo-mini {
          display: inline-block;
        }
        img {
          filter: brightness(0) invert(1);
          // width: 40px;
          // height: 40px;
        }
      }
    }
  }
} 

