.table-fix-head {
  overflow-y: auto;
 
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  thead th {
    position: sticky;
    top: 0;
    background: #ffffff;
  }
}

.mw-640 {
  max-width: 640px;
}

.live-posts {
  position: fixed;
  left: 0px;
  top: 64px;
  bottom: 0px;
  width: 0px;
  overflow: hidden;
  overflow-y: auto;
  transition: width .6s;
  background: #fff;
  z-index: 9;
  &.active {
    width: 480px;
  }
  & > div {
    padding: 36px 16px;
  }
}
.table-posts {
  tr, td {
    font-size: 0px;
    margin: 0px;
    border: 0px;
  }
  .post {
    height: 0px;
    overflow: hidden;
    transition: height .3s;
    &.active {
      height: 84px;
    }
    & > div {
      height: 100%;
      border-bottom: 1px solid #dee2e6;
      padding: 6px 12px;
      display: grid;
      grid-template-columns: 64px auto;
      column-gap: 10px;
      .image {
        height: 100%;
        width: 100%;
        background-color: #f2f2f2;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        border: 1px solid #609dff;
      }
      .info {
        position: relative;
        .title {
          display: block;
          font-size: 13px;
          white-space: normal;
          max-width: calc(100% - 80px);
        }
        .platform {
          opacity: 0.7;
          font-size: 11px;
        }
        .location {
          font-size: 12px;
        }
        .description {
          font-size: 11px;
          max-width: 60vw;
          white-space: normal;
          color: #909090;
        }
        .price {
          font-size: 13px;
          position: absolute;
          right: 0px;
          top: 0px;
        }
      }
    }
  }
}

.step {
  display: block;
  &.step-0 {
    .category {
      height: 90px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      display: grid;
      place-items: center;
      box-shadow: 0px 0px 4px 2px rgba(79, 93, 117, 0.55);
      transition: transform .2s;
      transform: scale(1);
      &:hover {
        transform: scale(1.06);
      }
      &.ribbon.new:after {
        content: "New!";
        background: #79A70A;
        background: linear-gradient(#9BC90D 0%, #79A70A 100%);
      }
      &.ribbon.soon:after {
        content: "Soon!";
        background: #888888;
        background: linear-gradient(#b3b3b3 0%, #888888 100%);
      }
      &.soon {
        opacity: 0.6;
        pointer-events: none;
        &:hover {
          transform: scale(1);
        }
      }
      span {
        
      }
    }
  }
  &.step-1 {
    
  }
  &.hidden {
    display: none;
  }
}

.ribbon:after {
  content: "New!";
  z-index: 1;
  overflow: hidden;
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg) scale(0.8);
  width: 120px;
  display: block; 
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 18px; 
  right: -32px;
  clip-path: polygon(120px 20px, 90px -10px, 30px -10px, 0px 20px, 10px 30px,  110px 30px);
}