/* === BuyersAssistant Color Variables === */

$primary:         #4f5d75;
$primary-dark:    #2d3142; 
$secondary:       #bbc0c4;
$content-bg:        #edf0f2;
$footer-bg:       #fff;
$footer-color:    #ffffff7c;
$border-color:    #dee2e6;
$sidebar-hover:   #4f5d75;

$success:         #28A745;
$info:            #17A2B8;
$warning:         #FFC107;
$danger:          #DC3545;
$blue:            #5E50F9;
$red:             #f96868;
$orange:          #f2a654;
$yellow:          #f6e84e;
$green:           #46c35f;
$black:           #000000;
$white:           #ffffff;
$light:           #fbfbfb;
$dark:            #212121;

$theme-colors: (
  primary:         $primary,
  primary-dark:    $primary-dark,
  secondary:       $secondary,
  success:         $success,
  info:            $info,
  warning:         $warning,
  danger:          $danger,
  light:           $light,
  dark:            $dark,
);

/* === Default Color Variables === */

$border-color:      #dee2e6;
$sidebar-hover:     #4f5d75;
$footer-bg:         #fff;
$footer-color:      #ffffff7c;
$circle-border:     #ffffff; 

////////// Color System //////////
$colors: (
  blue:             $blue,
  red:              $red,
  orange:           $orange,
  yellow:           $yellow,
  green:            $green,
  white:            $white,
  black:            $black,
);

$theme-colors: (
  primary:         $primary,
  primary-dark:    $primary-dark,
  secondary:       $secondary,
  success:         $success,
  info:            $info,
  warning:         $warning,
  danger:          $danger,
  light:           $light,
  dark:            $dark,
);

$theme-gradient-colors: (
  primary:         linear-gradient(to right, #da8cff, #9a55ff),
  secondary:       linear-gradient(to right, #e7ebf0, #868e96),
  success:         linear-gradient(to right, #84d9d2, #07cdae),
  info:            linear-gradient(to right, #90caf9, #047edf 99%),
  warning:         linear-gradient(to right, #f6e384, #ffd500),
  danger:          linear-gradient(to right, #ffbf96, #fe7096),
  light:           linear-gradient(to bottom, #f4f4f4, #e4e4e9),
  dark:            linear-gradient(89deg, #5e7188, #3e4b5b)
);

////////// Social Color //////////
$social-colors: (
  twitter: #2caae1,
  facebook: #3b579d,
  google: #dc4a38,
  linkedin: #0177b5,
  pinterest: #cc2127,
  youtube: #e52d27,
  github: #333333,
  behance: #1769ff,
  dribbble: #ea4c89,
  reddit: #ff4500
);
