$enable-rounded: false;
$enable-gradients: false;

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

.disabled {
  color: #b2b2b2 !important;
}

.block-animation {
  transition-property: none !important;
}