.permissions-list {
  width: 100%;
  td {
    font-size: 13px;
    width: 100%;
    button {
      i {
        margin: 0px;
      }
    }
  }
}