.checkbox-tree {
  position: relative;
  width: 100%;
  padding: 0px;
  border: 0px;
  &.disabled {
    pointer-events: none;
    background-color: #e9ecef;
  }
  .list {
    width: 100%;
    height: 100%;
    max-height: 260px;
    overflow-y: auto;
    padding: 10px;
    ul {
      list-style-type: none;
      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &.hidden {
          display: none;
        }
        .expand {
          display: inline-block;
          padding: 6px;
          cursor: pointer;
          font-size: 10px;
          opacity: 0.6;
          transition: opacity .3s;
          &:hover {
            opacity: 0.8;
          }
          &.hidden {
            opacity: 0;
            pointer-events: none;
          }
        }
        .check {
          display: inline-block;
          padding: 0px 6px;
          .form-group {
            margin: 0px;
            width: 15px;
            height: 15px;
            .form-check {
              margin: 0px;
              .form-check-label {
                margin-left: 15px;
                min-height: 7px;
              }
            }
          }
        }
        .label {
          display: inline-block;
          padding: 6px;
        }
        .children {
          flex: 0 0 100%;
        }
      }
    }
  }
  .ul-lvl-1 {
    padding-left: 0px;
    .ul-lvl-2 {
      padding-left: 12px;
      .ul-lvl-3 {
        padding-left: 32px;
      }
    }
  }
}