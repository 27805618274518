.version {
  position: absolute;
  white-space: nowrap;
  bottom: 8px;
  left: 8px;
  @media screen and (max-width: 991px) {
    left: auto;
    right: 8px;
  }
  font-size: 11px;
  color: $white;
  a {
    font-size: 11px;
    color: $white;
    text-decoration: none;
    .project-name {
      display: inline-block;
      width: 60px;
      overflow: hidden;
      transition: width .3s linear;
      vertical-align: bottom;
    }
    .semantic-version {
      display: inline-block;
      vertical-align: bottom;
    }
  }
}
.sidebar-icon-only {
  .version {
    .project-name {
      width: 0px;
    }
  }
}