.screen {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  opacity: 0;
  background: #f3f4fa;
  pointer-events: none;
  // transition: width .5s, padding .5s;
  // transition-delay: .4s;
  // width: 0px;
  z-index: 10;
  &.visible {
    overflow-y: auto;
    opacity: 1;
    pointer-events: all;
    // transition-delay: 0s;
    // width: 100%;
  }
}

.screen-toggle {
  cursor: pointer;
}