.content-wrapper {
  .page-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(255, 255, 255, 0.4);
    opacity: 0;
    z-index: 999;
    transition: opacity .3s;
    pointer-events: none;
    .loader-bar {
      position: fixed !important;
      top: 86px;
    }
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}