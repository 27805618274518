/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~react-loader-spinner/dist/loader/css/react-spinner-loader.css";
@import '~react-datepicker/dist/react-datepicker.css';

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";

// @import "~bootstrap/scss/variables";

// @import "../assets/styles/color-variables";

// @import "../assets/styles/color-variables";

@import "../assets/styles/core/themes/BuyersAssistant/color-variables";

// @import "../assets/styles/variables";

@import "../assets/styles/core/themes/BuyersAssistant/variables";

@import "../assets/styles/core/core.scss";

@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";

@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";
// @import "~font-awesome/scss/font-awesome";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

/* === Template mixins === */

@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";

/* === Core Styles === */

@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";
@import "../assets/styles/reports";

/* === Components === */

@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";

/* === Calendar === */

@import 'react-big-calendar/lib/sass/styles';

/* === Core === */

@import "../assets/styles/core/core.scss";

/* === App Custom === */

@import "../assets/styles/app/app.scss";