.item-picker {
  position: relative;
  width: 100%;
  padding: 0px;
  border: 0px;
  & > div {
    position: relative;
    padding: 2px;
    margin: auto;
    cursor: pointer;
    display: inline-block;
    margin-right: 8px;
    border: 1px solid #a0a0a0;
    transition: background .3s;
    &:hover {
      background: #1093c9;
      border: 1px solid #a0a0a0;
    }
    &.active {
      background: #1093c9;
      border: 1px solid #a0a0a0;
    }
    & > .check {
      position: absolute;
      width: 18px;
      height: 20px;
      border-radius: 50%;
      right: 6px;
      bottom: 6px;
      pointer-events: none;
      opacity: 0;
      transition: opacity .3s;
      i {
        font-size: 20px;
        line-height: 20px;
        color: #1093c9;
      }
    }
    &.active {
      & > .check {
        opacity: 1;
      }
    }
  }
}