.helper-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 999999;
  opacity: 0;
  pointer-events: none;
  transition: opacity .4s;
}
.helper-overlay.active {
  opacity: 1;
  pointer-events: all;
}
.helper-overlay .message {
  position: absolute;
  padding: 10px;
  background: #fff;
  max-width: 480px;
}
.helper-overlay .message.backdrop {
  box-shadow: 1px 1px 2px 2px rgba(136, 136, 136, 0.58);
}
.helper-overlay .message > div {
  white-space: pre-wrap;
}
.helper-overlay .control {
  width: 80px;
  height: 36px;
  line-height: 36px;
  background: #007cba;
  color: #fff;
  position: absolute;
  bottom: 60px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}
.helper-overlay .control.next {
  right: 60px;
}
.helper-overlay .control.skip {
  right: calc(80px + 60px + 30px);
}
.helper-overlay svg {
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.6;
}
.helper-button {
  cursor: pointer;
  opacity: 0.7;
  font-size: 14px;
}