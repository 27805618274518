.date-select {
  font-size: 11px;
  position: relative;
  .preview {
    cursor: pointer;
    height: 20px;
    line-height: 18px;
    border-bottom: 1px solid #c4c4c4;
    white-space: nowrap;
    i {
      margin-left: 10px;
    }
  }
  .data-view {
    display: none;
    z-index: 999;
    position: absolute;
    background: #ffffff;
    padding: 10px;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.12);
    top: 20px;
    right: 0px;
    width: 240px;
    @media (max-width: 991px) {
      left: 0px;
    }
    &.open {
      display: block;
    }
    .top-controls {
      width: 100%;
      display: grid;
      grid-template-columns: 48px 48px calc(100% - 48px - 48px - 38px - 38px) 38px 38px;
      div {
        text-align: center;
        cursor: pointer;
        color: #848484;
        font-weight: 400;
      }
      div.active {
        color: #1093c9;
        font-weight: 700;
      }
    }
    .central-controls {
      padding: 10px;
      padding-top: 18px;
      font-size: 12px;
      .relative-controls {
        display: grid;
        grid-template-columns: 48px 10px 10px 10px 10px 10px 10px;
        grid-gap: 12px;
        div {
          cursor: pointer;
          text-align: center;
          color: #848484;
          font-weight: 400;
        }
        div.active {
          text-decoration: underline;
          color: #1093c9;
          font-weight: 700;
        }
      }
      .absolute-controls {
        display: grid;
        grid-template-columns: 38px calc(100% - 52px);
        grid-gap: 12px;
        div {
          span {
            line-height: 32px;
          }
          .form-control {
            border: 0px;
            border-bottom: 1px solid #dee2e6;
            height: 2rem;
            line-height: 1.2rem;
          }
          .time-picker {
            width: calc(100% - 24px);
            height: 100%;
            border: 0px;
            border-bottom: 1px solid #dee2e6;
            color: #495057;
            padding: 6px 10px;
          }
        }
        .rdtPicker {
          right: 0px;
          left: auto;
        }
        @media (max-width: 620px) {
          .rdtPicker {
            right: auto;
            left: 0px;
          }
        }
      }
    }
    .bottom-controls {
      padding: 10px;
      text-align: right;
      input {
        padding: 4px 12px;
        background: transparent;
        border: 1px solid #1093c9;
        color: #1093c9;
        &:hover {
          background: #1093c9;
          color: #ffffff;
        }
      }
    }
  }
}