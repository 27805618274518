.searchable-multi-select {
  position: relative;
  width: 100%;
  padding: 0px;
  border: 0px;
  height: auto;
  
  &.disabled {
    pointer-events: none;
    background-color: #e9ecef;
  }
  .list {
    width: 100%;
    min-height: 32px;
    border: 1px solid #cecece;
    background-color: #ffffff;
    .list-items {
      width: 100%;
      max-height: 120px;
      // padding: 4px 0px 0px 4px;
      overflow-y: auto;
      .list-item {
        display: inline-block;
        position: relative;
        padding: 2px 4px;
        margin-right: 4px;
        margin-bottom: 4px;
        border: 1px solid #adadad;
        text-align: center;
        border-radius: 2px;
        cursor: pointer;
        background-color: #ffffff;
        .remove-item {
          pointer-events: none;
          position: absolute;
          display: block;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          top: 1px;
          right: 1px;
          opacity: 0;
          font-style: normal;
          background-color: rgba(255, 255, 255, 1);
          transition: opacity .3s;
        }
        @media (hover: none) {
          .remove-item {
            opacity: 1;
          }
        }
        &:hover {
          .remove-item {
            opacity: 1;
          }
        }
      }
    }
    .list-note {
      padding: 5px;
      position: absolute;
      color: #9e9e9e;
    }
  }
  input {
    display: inline-block;
    margin: 0px;
    height: auto;
    border: 1px solid #cecece;
    padding: 6px 24px 6px 8px;
    border: 0px;
    width: 100%;
    min-width: 80px;
    background-color: transparent;
  }
  ul {
    z-index: 999999;
    position: absolute;
    left: 0px;
    right: 0px;
    background-color: #fff;
    list-style: none;
    padding: 0px;
    border: 1px solid #cecece;
    max-height: 30vh;
    overflow-y: auto;
    @media (hover: none) {
      -ms-overflow-style: none; /* IE, Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar { /*Chrome, Safari and Opera */
        display: none;
      }
    }
    margin-bottom: 0px;
    li {
      padding: 8px 10px;
      background-color: #fff;
      cursor: pointer;
      &.selected {
        background-color: #64b1ff;
      }
      &.active {
        background-color: #3297FD;
        color: #fff;
      }
      &.disabled {
        cursor: default;
        opacity: 0.8;
        background-color: #fff;
      }
    }
  }
  .arrow-trigger {
    position: absolute;
    display: inline-block;
    padding: 10px;
    right: 4px;
    bottom: 2px;
    cursor: pointer;
    .arrow {
      width: 0;
      height: 0;
      vertical-align: middle;
      border-style: solid;
      &.arrow-down {
        border-width: 4px 4px 0 4px;
        border-color: #999 transparent transparent transparent;
      }
      &.arrow-up {
        border-width: 0px 4px 4px 4px;
        border-color: transparent transparent #999 transparent;
      }
    }
  }
}