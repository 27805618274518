.navbar {
  z-index: 1200;
}

.nav-profile {
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
  }
  .navbar-dropdown {
    // margin-top: 1rem !important;
    right: 0px !important;
    //margin-top: 0px !important;
    a {
      font-size: $font-size-base;
      i {
        font-size: 12px !important;
        span {
          font-size: $font-size-base;
          margin-left: 14px;
        }
      }
    }
  }
  
  @media (max-width: 991px) {
    .navbar-dropdown {
      // min-width: 254px !important;
    } 
  }
  
  @media (max-width: 375px) {
    .navbar-dropdown {
      // min-width: 200px !important;
    } 
  }

  .rounded-circle {
    -webkit-clip-path: circle(50% at 50% 50%); 
  }
  .dropdown {
    position: static!important;
  }
  .navbar-dropdown,
  .navbar-dropdown-large {
    margin: 0px !important;
  }
}

.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
  left: 48px;
  border-radius: 0;
  width: 190px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.12);
  border-right: 2px solid rgba(255, 255, 255, 0.12);
}

.sidebar-icon-only .sidebar .nav .nav-item .nav-link {
  border: none;
}

.sidebar-icon-only .sidebar .nav .nav-item {
  border-bottom: 2px solid rgba(255, 255, 255, 0.12);
}

.sidebar-icon-only .sidebar .nav.sub-menu {
  padding: 0px !important;
  border-top: 2px solid rgba(255, 255, 255, 0.12);
}

.sidebar .nav:not(.sub-menu) .active:not(.nav-profile) > .nav-link {
  border-right: 3px solid $border-color;
  @media (max-width: 1023px) {
     border-left: 3px solid #dee2e6;
     border-right: none;
  }
}

.nav-apps {
  button {
    margin-right: 12px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity .3s;
    text-align: left;
    &:hover {
       opacity: 0.9;
    }
  }
}